/* -------------------------------------------------------------------------- */
/*                                  Position                                  */
/* -------------------------------------------------------------------------- */
.container,
.container-fluid {
  position: relative;
}
.all-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.absolute-centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.absolute-horizontal-centered{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}