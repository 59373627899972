/*-----------------------------------------------
|   Button
-----------------------------------------------*/
.btn-link{font-weight: $font-weight-bold;}

.btn.btn-outline-light.border-2x { border-color: rgba($white, 0.5); }

.btn-outline-light:hover, .btn-light, .btn-light:hover { color: $dark; }
.btn-xs{ padding: .2rem .8rem; }

.btn-success, .btn-info{
  color: $white;
}

.btn-primary{
  color: $gray-1000;
}

.btn-ensurance{
  position: relative;
  border-radius: 30px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  overflow:hidden;
  width: 60px; 
  transition: width 0.4s;
  &:hover,&:focus{
    width: 210px;
    color: $white;
    background-color: $black;
    border-color: $black;
  }
  .btn-icon {
    font-size: 10px;
    margin-left: auto;
    display: flex;
  }
  
  .btn-text {
    position: absolute;
    white-space: nowrap;
    left: 58px;
  }
}


