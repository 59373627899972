/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  // overflow: hidden;
  .hoverbox-content {
    position: absolute;
    border-radius: 1rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin: 0 !important;
    display: flex;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(4rem);
    a:hover{
      opacity: .8;
    }
  }

  @include hover-focus {
    .hover-box-content-initial { opacity: 0; height:100%; }
    .hoverbox-content { opacity: 1; }
    .as-hoverbox-content { z-index: 1; }
  }
}

.hoverbox-content-gradient { background: linear-gradient(transparent, var(--#{$variable-prefix}black)); }


/* Hover */
.hover-top {
  transform: translateY(0) translateZ(0);
  transition: all 0.5s ease;
  &.hover-top-shadow-lg {
    &:hover,
    &:focus {
      transform: translateY(-0.3125rem) translateZ(0);
    }
  }
  &:hover,
  &:focus {
    transform: translateY(-0.125rem) translateZ(0);
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(110, 74, 156, 0.1) !important;
    background-color: $pink;
    color: $white;
    .heading-color{
      color: $white;
    }
  }
  
}