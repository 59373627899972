/* -------------------------------------------------------------------------- */
/*                               Browser Fixing                               */
/* -------------------------------------------------------------------------- */

// Firefox fixed
.firefox{
  .dropcap:first-letter{ margin-top: 0.175em; }
  .card-notification .scrollbar-overlay {
    min-height:19rem;
  }
}

// .chrome{
//   .btn-close{
//     background-clip: unset;
//   }
// }