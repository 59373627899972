/*-----------------------------------------------
|   Form
-----------------------------------------------*/
.form-floating {
  position: relative;

  > label{
    font-size: map_get($font-sizes, '1');
    color: $gray-500;
    padding: 1rem 0;
  }
  > .form-control{
    &:focus{
       + label{
        color: $white;
      }
    }
  }
}

.form-search-control{
  padding: .5rem .5rem;
}

.input-group-icon{
  position: relative;
  .input-box{
    padding-left: 2.8rem;
    vertical-align: middle;
  }
}

.form-ensurance-control{
  border-radius: 40px 40px 40px 40px;
  padding: $input-padding-y 2.4rem;
  background-color: #29313C;
  border: 1px solid $primary;
}

.btn-subscribe{
  position:absolute;
  top: 0;
  right: 0;
  border-radius: 40px 40px 40px 40px;
}

//Header

.input-group-ensurance-icon{
  position: relative;
  .input-box{
    padding-left: 2.8rem;
    vertical-align: middle;
  }
  .input-box-ensurance-icon{
    position: absolute;
    top:50%;
    left: 1rem;
    color:$gray-300;
    transform: translateY(-50%);
  }
}


// - form control control
//
// General form controls (plus a few specific high-level interventions)
//

.form-ensurance-header-control {
  background-color: transparent;
  background-clip: padding-box;
  border: 0 solid $input-border-color;
  border-bottom: 1px solid #5edcd44a;
  border-radius: 0;
  color: $gray-100;
  &:hover, &:focus{
    background-color: transparent;
    color: $gray-500;
    border-bottom: 1px solid #5edcd4c7;
  }
 
  
}


